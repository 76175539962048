/* Universal */
body {
  background-color: #eaeaea;
  padding-bottom: 10px;
  font-family: Arial, sans-serif;
}

/* Universal in Main */
.App {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Main game styling */
.thegame-text {
  color: #000000;
  padding: 0px;
  font-size: larger;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.thegame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.thegame p {
  padding-right: 10px;
}
.thegame input {
  margin-top: 3px;
}
.thegame button {
  margin-top: -10px;
}

.checkanswerbutton {
  border: #000000;
  border-width: 1px;
  border-style: outset;
}

/* Universal element styling */
h1,
h2 {
  padding-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
p {
  margin-bottom: 5px;
}

input {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 6px;
  padding: 8px;
  border-radius: 10px;
}

button {
  padding: 10px;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #4c6daf;
}
button:hover {
  background-color: #385fa8;
}
button:active {
  background-color: #2f5191;
}

/* Feedback text */
.feedback-container {
  height: 30px; /* Adjust the height as needed */
  margin-bottom: 10px;
  overflow: hidden;
}
.feedback-text {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  font-size: larger;
  color: green; /* Set the default text color to green */
}
.incorrect {
  color: red; /* Set the text color to red for incorrect answers */
}

/* Progress meter */
.progresstext {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Endscreen */
.endscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  font-size: larger;

  background-color: #dddddd;
  border: #000000;
  border-width: 2px;
  border-style: outset;
  border-radius: 20px;

  margin-left: 40px;
  margin-right: 40px;
  padding-bottom: 20px;
}
.endscreen ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  text-align: left; /* Align text to the left within the list items */
}
.endscreen li {
  margin-bottom: 5px; /* Add some spacing between list items */
  margin-left: 5px;
}
.endcorrect {
  align-items: center;
}

/* Endscreen buttons */
.restartbutton {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  width: 150px;
  height: 50px;
  margin-top: 10px;
  border: #000000;
  border-width: 2px;
  border-style: outset;
}

.revealbutton {
  width: 150px;
  height: 50px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgb(50, 50, 50);
}
.revealbutton:hover {
  background-color: rgb(40, 40, 40);
}
.revealbutton:active {
  background-color: rgb(30, 30, 30);
}

/* Main menu buttons */
.switchbutton {
  width: 180px;
  height: 60px;
  background-color: rgb(65, 65, 65);
}
.switchbutton:hover {
  background-color: rgb(50, 50, 50);
}
.switchbutton:active {
  background-color: rgb(30, 30, 30);
}

.startgamebutton {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 180px;
  height: 60px;
  border: #000000;
  border-radius: 10px;
  border-width: 3px;
  border-style: outset;
}

.start1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.start2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formbuttons {
  display: flex;
  justify-content: center;
}

/* Managepanel buttons */
.addwordbutton {
  background-color: rgb(10, 148, 10);
  min-width: 90px;
  max-width: 120px;
  height: 50px;
  margin: 5px;
}
.addwordbutton:hover {
  background-color: rgb(8, 121, 8);
}

.showwordsbutton {
  background-color: rgb(65, 65, 65);
  min-width: 90px;
  max-width: 120px;
  height: 50px;
  margin: 5px;
}
.showwordsbutton:hover {
  background-color: rgb(50, 50, 50);
}

.deletebutton {
  background-color: rgb(180, 0, 0);
  width: 120px;
  height: 50px;
  margin: 5px;
}
.deletebutton:hover {
  background-color: rgb(160, 0, 0);
}

.hcbutton {
  min-width: 90px;
  max-width: 120px;
  height: 50px;
  margin: 5px;
}

/* Auth panel styling */
.panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.authpanel {
  background-color: #c0c0c0;
  border: #000000;
  border-width: 2px;
  border-style: outset;
  border-radius: 20px;

  min-width: 250px;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  margin: 20px;
}

.authpanel button {
  border: #000000;
  border-radius: 10px;
  border-width: 2px;
  border-style: outset;
  margin-left: 5px;
}

.authpanel input {
  background-color: rgb(245, 245, 245);
}

/* Manage panel styling */
.managepanel {
  background-color: #c0c0c0;
  border: #000000;
  border-width: 2px;
  border-style: outset;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  margin: 20px;
}

.managepanel h1,
h2 {
  padding-bottom: 10px;
}

.managepanel button {
  border: #000000;
  border-radius: 10px;
  border-width: 2px;
  border-style: outset;
}

.managepanel input {
  background-color: rgb(245, 245, 245);
}

.listid {
  color: rgb(160, 0, 0);
}

/* Navbar styling */
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  background-color: #333333;
  color: azure;
  text-decoration: none;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  text-decoration: none;
}

/* Info page styling */
.info-bg {
  background-color: rgb(34, 34, 34);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
  padding-bottom: 20px;
  min-height: 91vh;
}
